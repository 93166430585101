@import "~antd/dist/antd.css";

:root {
  --main-bg-color: #f0f2f5;
}

html, body, #root {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.app-visitor {
  padding-top: 4%;
}

.app-content {
  margin: 0 auto;
  width: 100%;
}

.ant-layout {
  min-height: 100vh;
  /* min-width: 100vw; */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(240, 242, 245, 0.3) 0%,
    rgba(143, 162, 189, 0.8) 100%
  );
}

.half-width {
  width: 50%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.justify-c-center {
  justify-content: center;
}

.justify-c-start {
  justify-content: flex-start;
}

.justify-c-sb {
  justify-content: space-between;
}

.justify-c-sa {
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.center-button {
  margin: auto;
  display: block;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-top-large {
  margin-top: 44px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

.full-width {
  width: 100%;
}



#unpadded-card .ant-card-body {
  padding: 0;
}

@media (max-width: 600px) {
  .app-content {
    margin: 0;
  }

  .login-secondary-cards-container {
    flex-wrap: wrap;
  }

  /* .ant-layout-has-sider {
    min-width: min-content;
  } */

  .ant-layout-sider-trigger {
    position: sticky;
  }

  #section-header-card {
    max-width: 100vw;
  }

  .section-content-container {
    margin: 24px 3px;
  }
}

/* OVERRIDES  */
.ant-layout-footer {
  background: #a9b7cc;
}


.section-content-container {
  padding: 24px 
}