.sidebar-menu-item {
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;
}

.sidebar {
  background-color: white !important;
  border-right: 0px !important;
}

.sidebar.collapsible {
  position: absolute;
  z-index: 1;
  height: 100%;
}

.sidebar .ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 52px;
  line-height: 40px;
}

.sidebar .ant-divider-horizontal {
  margin: 12px 0;
}

.sidebar .ant-menu-item-selected {
  font-weight: 600;
  color: #f09f42 !important;
}

.sidebar .ant-menu-item {
  color: #000000 !important;
}

.sidebar .ant-menu-item {
  color: #000000 !important;
}

.sidebar .ant-menu-item:hover {
  color: #1890ff !important;
}

.sidebar .ant-menu-item-disabled {
  color: #000000 !important;
}

.sidebar .ant-layout-sider-trigger {
  background-color: #f04842;
}

.sidebar .ant-menu {
  color: #fff;
  margin-top: 34px;
}

.sidebar .ant-menu-sub.ant-menu-inline {
  background: inherit;
}
.sidebar .ant-menu-submenu-selected {
  color: #000000 !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-active {
  color: #000000 !important;
  font-weight: 600;
}

.sidebar .ant-menu-item-active {
  color: currentColor !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-arrow {
  color: #ffff;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-title {
  padding-left: 17px !important;
  color: #000000;
}

.sidebar .ant-menu-submenu-title-disabled {
  padding-left: 17px !important;
  color: #000000;
}

.ant-menu-item-disabled > .ant-menu-submenu-title {
  padding-left: 17px !important;
  color: #000000;
}

.sidebar.ant-layout-sider-collapsed {
  display: none;
}

.sidebar-collapse-trigger {
  position: fixed;
  z-index: 2;
  font-size: 1.75em;
  top: 15px;
  left: 15px;
}

:root {
  --main-bg-color: #f0f2f5;
}

html, body, #root {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.app-visitor {
  padding-top: 4%;
}

.app-content {
  margin: 0 auto;
  width: 100%;
}

.ant-layout {
  min-height: 100vh;
  /* min-width: 100vw; */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(240, 242, 245, 0.3) 0%,
    rgba(143, 162, 189, 0.8) 100%
  );
}

.half-width {
  width: 50%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.justify-c-center {
  -webkit-justify-content: center;
          justify-content: center;
}

.justify-c-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.justify-c-sb {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.justify-c-sa {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.center-button {
  margin: auto;
  display: block;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-top-large {
  margin-top: 44px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

.full-width {
  width: 100%;
}



#unpadded-card .ant-card-body {
  padding: 0;
}

@media (max-width: 600px) {
  .app-content {
    margin: 0;
  }

  .login-secondary-cards-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  /* .ant-layout-has-sider {
    min-width: min-content;
  } */

  .ant-layout-sider-trigger {
    position: -webkit-sticky;
    position: sticky;
  }

  #section-header-card {
    max-width: 100vw;
  }

  .section-content-container {
    margin: 24px 3px;
  }
}

/* OVERRIDES  */
.ant-layout-footer {
  background: #a9b7cc;
}


.section-content-container {
  padding: 24px 
}
.header {
  background-color: var(--main-bg-color);
}


.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 24px;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  background-color: #f8f9fb;
}


.header-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 60px;
  -webkit-flex: 1 1;
          flex: 1 1;
-webkit-justify-content: flex-end;
        justify-content: flex-end;
}

#nav {
  height: 100%;
  font-size: 12px;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
}

.icon-logo {
  display: none;
}

img.icon-logo {
  height: 32px;
  position: relative;
  bottom: 13px;
  left: 9px;
}

.show-sm {
  display: none;
}

.more-menu-icon {
  font-size: 1.6rem;
}

@media (max-width: 600px) {
  .full-logo {
    display: none;
  }
  .icon-logo {
    display: inherit;
  }
  .header-actions {
    margin-left: 12px;
    padding-right: 0px;
  }

  .header-top {
    -webkit-justify-content: unset;
            justify-content: unset;
  }

  .show-lg {
    display: none;
  }

  .show-sm {
    display: inline-block;
    margin: 0 15px;
  }

  .header-acc-button {
    white-space: inherit !important;
    height: auto !important;
    margin-left: 5px;
  }
}

/*  AUTH STYLES */
.auth-card {
  max-width: 600px;
  margin: auto;
}

/* Login STYLES */

.login-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.login-container .ant-card-body {
  padding-bottom: 0;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.login-form {
  width: 400px;
  max-width: 100%;
  margin: auto;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.mini-card-login {
  margin: 10px 5px;
  -webkit-align-items: center;
          align-items: center;
}

.auth-container {
  margin: auto 1em;
  padding-bottom: 2em;
}

.signup-advertising-title {
  margin-bottom: 40px;
}

.signup-advertising-element {
  font-size: 1.2rem !important;
}

.signup-advertising-image {
  width: 30%;
  max-width: 320px;
  min-width: 240px;
  opacity: 0.7;
  margin: 14px auto;
}

.signup-divider {
  display: none;
}

@media (max-width: 700px) {
  .signup-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .signup-advertising {
    max-width: none;
  }

  .signup-advertising-title {
    font-size: 1.3rem !important;
  }

  .signup-advertising-element {
    font-size: 1rem !important;
  }

  .signup-advertising-image {
    display: none;
  }

  .signup-divider {
    display: -webkit-flex;
    display: flex;
  }
  .auth-card {
    max-width: 100vw;
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
  }

  .first-image-auth {
    width: 200px !important;
    position: unset !important;
    -webkit-align-self: center;
            align-self: center;
  }
}

.second-image-auth {
  width: 180px;
  position: absolute;
  bottom: 10%;
  left: 9%;
  opacity: 0.8;
}

.first-image-auth {
  width: 170px;
  position: absolute;
  left: 30px;
  top: 30px;
  opacity: 0.8;
}

.disconnect{
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  color: red;
}
.sigCanvas {
  border: 2px solid #e3ecf8;
  cursor: crosshair;
}

.initial-config-card {
  margin: 10% 8%;
}

.initial-config-steps-container {
  padding: 30px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.sig-pad-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.responsive-sigpad-msg {
  display: none;
}

.signature-radio-group {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 600px) {
  .initial-config-card {
    margin: 10px 3px;
  }
  .initial-config-steps-container {
    padding: 0;
    margin-top: 0;
  }

  #initial-config-stripe .ant-btn-lg {
    height: auto;
  }
  #initial-config-stripe .ant-btn {
    white-space: inherit;
  }
  .sig-pad-container {
    display: none;
  }
  .responsive-sigpad-msg {
    display: block;
  }
  .signature-radio-group {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .signature-radio-group .ant-upload-list {
    max-width: 60vw;
  }
}

.sigCanvas {
  border: 2px solid #e3ecf8;
  cursor: crosshair;
}

.initial-config-card {
  margin: 10% 8%;
}

.initial-config-steps-container {
  padding: 30px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.sig-pad-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.responsive-sigpad-msg {
  display: none;
}

.signature-radio-group {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 600px) {
  .initial-config-card {
    margin: 10px 3px;
  }
  .initial-config-steps-container {
    padding: 0;
    margin-top: 0;
  }

  #initial-config-stripe .ant-btn-lg {
    height: auto;
  }
  #initial-config-stripe .ant-btn {
    white-space: inherit;
  }
  .sig-pad-container {
    display: none;
  }
  .responsive-sigpad-msg {
    display: block;
  }
  .signature-radio-group {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .signature-radio-group .ant-upload-list {
    max-width: 60vw;
  }
}

.telec-modal .ant-modal-body {
    padding: 0;
}

.telec-modal #dashboard-telec-element {
    margin: 33px 0 0 !important;
}

.device-icon {
    font-size: 35px;
    margin-top: 10px;
    color: #50abff;
}

#dashboard-telec-element .ant-card-body { 
    padding: 24px;
}

#dashboard-telec-element .ant-card-head-title {
    font-size: 14px;
}

#water-wave-card .ant-card-body{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.link-button-title {
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
    font-size: 16px;
}

.dashboard-incoming-telec-container {
    margin-right: 24px;
    -webkit-flex: 63% 1;
            flex: 63% 1;
    margin-bottom: 24px;
}

@media (max-width: 600px) {
    .dashboard-incoming-telec-container {
        margin-right: 0;
    }

    .dashboard-incoming-telec-container .ant-btn { 
        white-space: break-spaces;
    }

}
.profilePicture-container {
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 0 1;
          flex: 0 1;
  max-width: 200;
}

.user-card-container {
    -webkit-justify-content: space-between;
            justify-content: space-between;   
}

.user-card-statistics {
    -webkit-align-items: center;
            align-items: center;
}

.user-card-identity {
    margin-left: 1rem;
    margin-top: 10px; 
}

.user-card-divider{
    height: 40px;
}

@media (max-width: 600px) {
    .user-card-container {
        margin-left: -16px;
    }

    .user-card-statistics {
        margin-top: 12px;
    }


    .user-card-divider {
        display: none;
    }
}
#studies-form .item {
  margin: 5px;
}

#studies-form .add {
  margin-top: 10px;
  -webkit-align-self: center;
          align-self: center;
}

#biography-form .item {
  max-width: 40%;
}

.studies-inputs-container {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.diploma-input {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.ant-row .ant-form-item{
  display: -webkit-flex;
  display: flex;
}

.pricing-inputs-container > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 40%;
  -webkit-justify-content: center;
          justify-content: center;
}

.ant-form-item-label{
  display: -webkit-flex;
  display: flex;
}
.ant-input-number{
  width:100%
}

#pricing-form .add {
  margin-top: 10px;
}

@media (max-width: 600px) {
  #profile-complete-card .user-card-identity {
    width: auto;
    margin-top: 12px;
  }
  .studies-inputs-container {
    -webkit-justify-content: unset;
            justify-content: unset;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: -40px;
  }

  .diploma-input {
    -webkit-flex: 0 1;
            flex: 0 1;
  }

  .studies-table {
    margin-left: -20%;
  }

  #biography-form .item {
    max-width: unset;
  }

  .studies-table .ant-table-cell:last-child {
    padding: 0;
  }

  .initial-config-last-result {
    padding: 0
  }

  .initial-config-last-result .ant-btn {
    height: auto;
    white-space: inherit;
  }

  #profile-complete-card .ant-transfer-list {
    width: 140px;
  }

  #profile-complete-card .studies-inputs-container {
    margin-left: 0;
  }

  #profile-complete-card .ant-form-item {
    max-width: 50vw;
  }
}

@media (max-width: 992px) {
  #react-week-calendar .show-sm {
    display: block !important;
  }
  #react-week-calendar .hidden-sm {
    display: none !important;
  }
  #react-week-calendar .week {
    display: block !important;
    width: 100%;
  }
  #react-week-calendar .day {
    max-width: 100% !important;
    width: 100%;
    margin-top: 10px !important;
    margin: 0px !important;
  }
  #react-week-calendar .event-list {
    margin: 10px 0px;
  }

  #react-week-calendar .row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .calendar-responsive-header {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }
}

#react-week-calendar .row.event-list {
  width: 100%;
}

#react-week-calendar .event-list-parent {
  padding: 0px !important;
}

#react-week-calendar .hidden-lg {
  display: none;
}

#react-week-calendar .hidden-sm {
  display: none;
}

#react-week-calendar .show-lg {
  display: -webkit-flex;
  display: flex;
}

#react-week-calendar .week {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}

#react-week-calendar .day {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0px 2.5px;
}

#react-week-calendar .day:last-child {
  margin-right: 0px !important;
}

#react-week-calendar .day:first-child {
  margin-left: 0px !important;
}

#react-week-calendar .calendar-container {
  min-height: 200px;
}

#react-week-calendar .day-event {
  background: #e8e8e8;
  padding: 10px;
  margin: 2.5px 0px;
  width: 100%;
  word-break: break-all;
  cursor: pointer;
}

#react-week-calendar .day-event:last-child {
  margin-bottom: 0px !important;
}

#react-week-calendar .day-event:first-child {
  margin-top: 0px !important;
}

#react-week-calendar .week-header {
  /* background: #337ddf; */
  padding: 20px 0px;
  width: 100%;
}

#react-week-calendar .box-button {
  border-radius: 0 !important;
}

#react-week-calendar .row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-left: 1px solid #f0f0f0;
}

#react-week-calendar .row:last-child {
  border-right: 1px solid #f0f0f0;
}

#react-week-calendar .col-12:last-child {
  border-right: 1px solid #f0f0f0;
}

#react-week-calendar .col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

#react-week-calendar .empty-state {
  color: #e8e8e8;
}

#react-week-calendar .btn {
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 0px;
  outline: none;
  border: none;
  transition-duration: 0.2s;
}

#react-week-calendar .btn:active {
  border-style: outset;
  border: none;
  background-color: #7ea8df;
}

#react-week-calendar .fade {
  -webkit-animation: fade;
          animation: fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.calendar-container {
  border: 1px solid #f0f0f0;
}

.calendar-event-button {
  margin: 10px 3px;
  /* border-color: #5bb5f2; */
  /* color: #5bb5f2; */
}

.calendar-responsive-header {
  display: none;
}
/* .calendar-event-button:hover {
  border-width: 2px;
  border-color: #8ddf4e;
  color: #8ddf4e;
} */

.calendar-body-container {
  padding: 0 48px;
  min-height: 156px;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes btn-click {
  from {
    background-color: white;
  }
  to {
    background-clip: #337ddf;
  }
}

@keyframes btn-click {
  from {
    background-color: white;
  }
  to {
    background-clip: #337ddf;
  }
}

@media (max-width: 600px) {
  .calendar-body-container {
    padding: 0;
  }
}

#agenda .badge {
  margin: 5px;
}

#agenda .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

@media (max-width: 600px) {
  #agenda .ant-btn-lg {
    height: auto;
  }
  #agenda .ant-btn {
    white-space: inherit;
  }

  #agenda-add-multiple .ant-radio-wrapper {
    white-space: inherit;
  }
}

.ant-descriptions-view table {
  width: 40%;
  table-layout: fixed;
}

.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: -webkit-min-content;
  height: min-content;
  max-height: calc(100vh - 180px);
  overflow: auto;
  width: 100%;
  margin-right: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.selected-patient {
  background-color: #337ab72e;
}

.authorized-doctors-card .ant-card-body {
  padding-top: 10px;
}

.patient-card .ant-card-body {
  padding: 0;
}

.patient-images-container {
  -webkit-align-content: center;
          align-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.rotate-buttons {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 48vw;
  margin-bottom: 14px;
}

@media (max-width: 600px) {
  .patient-images-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .image-gallery {
    margin-right: 60px;
    max-width: unset;
  }

  .rotate-buttons {
    width: unset;
    margin-right: 60px;
  }
}

.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: -webkit-min-content;
  height: min-content;
  max-height: calc(100vh - 180px);
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
  max-width: 35%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.documents-container {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.doc-file-radio {
  height: 12em;
  width: 15em;
  margin: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.authorized-doctors-list .ant-list-item {
  padding: 16px 0;
}

.authorized-doctors-list .ant-list-item-action  button {
  color: #ff4d4f;
}

@media (max-width: 600px) {
  .images-container {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

#agenda .badge {
    margin: 5px;
  }
  
  #agenda .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
  
  @media (max-width: 600px) {
    #agenda .ant-btn-lg {
      height: auto;
    }
    #agenda .ant-btn {
      white-space: inherit;
    }
  
    #agenda-add-multiple .ant-radio-wrapper {
      white-space: inherit;
    }
  }
  
  .ant-descriptions-view table {
    width: 40%;
    table-layout: fixed;
  }
  
.fc .fc-col-header-cell-cushion { /* needs to be same precedence */
    width: 50px; /* an override! */
    padding-bottom: 5px; /* an override! */
    font-size:16px;
    color:green;
}

.fc-daygrid-event-harness{
    background-color: rgb(201, 145, 129);
    margin: 5px;
}

.fc .fc-daygrid-day-events {
    margin-top: 15px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    color:white;
}
#local-video-preview {
  width: 450px;
  height: 450px;
}
.vid-preview-item {
  margin: 0 10px;
}

#local-video {
  max-width: 80%;
  max-height: 100%;
  display: block;
  margin: 10px auto 20px;
}

#remote-video {
  height: 100%;
  max-width: 80%;
  display: block;
  margin: 10px auto 20px;
}

.message--sent {
  position: absolute;
  right: 3.15rem;
  bottom: -1.075rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.message--sent svg {
  margin-left: 0.25rem;
}

.message--sent-date {
  position: absolute;
  left: 3.75rem;
  bottom: -1.075rem;
}

.message-text-contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 1rem;
  margin: -0.625rem 0 0.25rem 0.75rem;
  background-color: #f0f2f5;
  word-wrap: anywhere;
 
}

.message:not(:first-child) {
  margin-top: 2rem;
}
.message:first-child {
  margin-top: 1rem;
}


.message-text-user {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: -0.625rem 0.75rem 0.25rem 0;
  font-size: 1rem;
  background-color: #1890ff;
  color: #fff;
  word-wrap: anywhere;
}


.message-user-receiver {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.message { 
  display: -webkit-flex; 
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;

}

.message > .ant-avatar.ant-avatar-icon {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}


.googleplay-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  -webkit-align-self: start;
          align-self: start;
  margin-bottom: 10px;
}

.on-start-tab .ant-form-item {
  margin-bottom: 10px;
}

.on-start-tab .ant-row {
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
}

.on-start-tab .ant-form-item-label {
  text-align: left;
}

.rc-tabs-0-panel-2 {
  background-color: #f6ffed;
}

.rc-tabs-0-panel-3 {
  background-color: #e6f7ff;
}

.telec-messages-card {
  height: 80vh;
}

.telec-messages-card .ant-card-body {
  height: 81%;
}

#patient-info-modal .ant-descriptions-view table {
  width: 100%;
}

.telec-messages-card .message-input-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.telec-messages-card .message-input-wrapper .input-formitem{
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.telec-messages-card .message-input-wrapper .input-actions{
  margin-left: 1em;
}
