.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: min-content;
  max-height: calc(100vh - 180px);
  overflow: auto;
  width: 100%;
  margin-right: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.selected-patient {
  background-color: #337ab72e;
}

.authorized-doctors-card .ant-card-body {
  padding-top: 10px;
}

.patient-card .ant-card-body {
  padding: 0;
}

.patient-images-container {
  align-content: center;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.rotate-buttons {
  justify-content: space-between;
  width: 48vw;
  margin-bottom: 14px;
}

@media (max-width: 600px) {
  .patient-images-container {
    flex-wrap: wrap;
  }

  .image-gallery {
    margin-right: 60px;
    max-width: unset;
  }

  .rotate-buttons {
    width: unset;
    margin-right: 60px;
  }
}
