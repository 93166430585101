@media (max-width: 992px) {
  #react-week-calendar .show-sm {
    display: block !important;
  }
  #react-week-calendar .hidden-sm {
    display: none !important;
  }
  #react-week-calendar .week {
    display: block !important;
    width: 100%;
  }
  #react-week-calendar .day {
    max-width: 100% !important;
    width: 100%;
    margin-top: 10px !important;
    margin: 0px !important;
  }
  #react-week-calendar .event-list {
    margin: 10px 0px;
  }

  #react-week-calendar .row {
    flex-direction: row !important;
  }

  .calendar-responsive-header {
    display: flex !important;
    justify-content: space-around;
  }
}

#react-week-calendar .row.event-list {
  width: 100%;
}

#react-week-calendar .event-list-parent {
  padding: 0px !important;
}

#react-week-calendar .hidden-lg {
  display: none;
}

#react-week-calendar .hidden-sm {
  display: none;
}

#react-week-calendar .show-lg {
  display: flex;
}

#react-week-calendar .week {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}

#react-week-calendar .day {
  flex-basis: 0;
  flex: 1 1 0;
  margin: 0px 2.5px;
}

#react-week-calendar .day:last-child {
  margin-right: 0px !important;
}

#react-week-calendar .day:first-child {
  margin-left: 0px !important;
}

#react-week-calendar .calendar-container {
  min-height: 200px;
}

#react-week-calendar .day-event {
  background: #e8e8e8;
  padding: 10px;
  margin: 2.5px 0px;
  width: 100%;
  word-break: break-all;
  cursor: pointer;
}

#react-week-calendar .day-event:last-child {
  margin-bottom: 0px !important;
}

#react-week-calendar .day-event:first-child {
  margin-top: 0px !important;
}

#react-week-calendar .week-header {
  /* background: #337ddf; */
  padding: 20px 0px;
  width: 100%;
}

#react-week-calendar .box-button {
  border-radius: 0 !important;
}

#react-week-calendar .row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-left: 1px solid #f0f0f0;
}

#react-week-calendar .row:last-child {
  border-right: 1px solid #f0f0f0;
}

#react-week-calendar .col-12:last-child {
  border-right: 1px solid #f0f0f0;
}

#react-week-calendar .col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

#react-week-calendar .empty-state {
  color: #e8e8e8;
}

#react-week-calendar .btn {
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 0px;
  outline: none;
  border: none;
  transition-duration: 0.2s;
}

#react-week-calendar .btn:active {
  border-style: outset;
  border: none;
  background-color: #7ea8df;
}

#react-week-calendar .fade {
  animation: fade;
  animation-duration: 1s;
}

.calendar-container {
  border: 1px solid #f0f0f0;
}

.calendar-event-button {
  margin: 10px 3px;
  /* border-color: #5bb5f2; */
  /* color: #5bb5f2; */
}

.calendar-responsive-header {
  display: none;
}
/* .calendar-event-button:hover {
  border-width: 2px;
  border-color: #8ddf4e;
  color: #8ddf4e;
} */

.calendar-body-container {
  padding: 0 48px;
  min-height: 156px;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes btn-click {
  from {
    background-color: white;
  }
  to {
    background-clip: #337ddf;
  }
}

@media (max-width: 600px) {
  .calendar-body-container {
    padding: 0;
  }
}
