#local-video-preview {
  width: 450px;
  height: 450px;
}
.vid-preview-item {
  margin: 0 10px;
}

#local-video {
  max-width: 80%;
  max-height: 100%;
  display: block;
  margin: 10px auto 20px;
}

#remote-video {
  height: 100%;
  max-width: 80%;
  display: block;
  margin: 10px auto 20px;
}

.message--sent {
  position: absolute;
  right: 3.15rem;
  bottom: -1.075rem;
  display: flex;
  align-items: center;
}

.message--sent svg {
  margin-left: 0.25rem;
}

.message--sent-date {
  position: absolute;
  left: 3.75rem;
  bottom: -1.075rem;
}

.message-text-contact {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 1rem;
  margin: -0.625rem 0 0.25rem 0.75rem;
  background-color: #f0f2f5;
  word-wrap: anywhere;
 
}

.message:not(:first-child) {
  margin-top: 2rem;
}
.message:first-child {
  margin-top: 1rem;
}


.message-text-user {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: -0.625rem 0.75rem 0.25rem 0;
  font-size: 1rem;
  background-color: #1890ff;
  color: #fff;
  word-wrap: anywhere;
}


.message-user-receiver {
  flex-direction: row-reverse;
}

.message { 
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;

}

.message > .ant-avatar.ant-avatar-icon {
  flex-shrink: 0;
}


.googleplay-button {
  display: flex;
  align-items: center;
  height: auto;
  align-self: start;
  margin-bottom: 10px;
}

.on-start-tab .ant-form-item {
  margin-bottom: 10px;
}

.on-start-tab .ant-row {
  flex-flow: column wrap;
}

.on-start-tab .ant-form-item-label {
  text-align: left;
}

.rc-tabs-0-panel-2 {
  background-color: #f6ffed;
}

.rc-tabs-0-panel-3 {
  background-color: #e6f7ff;
}

.telec-messages-card {
  height: 80vh;
}

.telec-messages-card .ant-card-body {
  height: 81%;
}

#patient-info-modal .ant-descriptions-view table {
  width: 100%;
}

.telec-messages-card .message-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.telec-messages-card .message-input-wrapper .input-formitem{
  flex-grow: 1;
}

.telec-messages-card .message-input-wrapper .input-actions{
  margin-left: 1em;
}