/*  AUTH STYLES */
.auth-card {
  max-width: 600px;
  margin: auto;
}

/* Login STYLES */

.login-container {
  display: flex;
  justify-content: center;
}
.login-container .ant-card-body {
  padding-bottom: 0;
  flex: 1;
}

.login-form {
  width: 400px;
  max-width: 100%;
  margin: auto;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.mini-card-login {
  margin: 10px 5px;
  align-items: center;
}

.auth-container {
  margin: auto 1em;
  padding-bottom: 2em;
}

.signup-advertising-title {
  margin-bottom: 40px;
}

.signup-advertising-element {
  font-size: 1.2rem !important;
}

.signup-advertising-image {
  width: 30%;
  max-width: 320px;
  min-width: 240px;
  opacity: 0.7;
  margin: 14px auto;
}

.signup-divider {
  display: none;
}

@media (max-width: 700px) {
  .signup-container {
    flex-wrap: wrap;
  }

  .signup-advertising {
    max-width: none;
  }

  .signup-advertising-title {
    font-size: 1.3rem !important;
  }

  .signup-advertising-element {
    font-size: 1rem !important;
  }

  .signup-advertising-image {
    display: none;
  }

  .signup-divider {
    display: flex;
  }
  .auth-card {
    max-width: 100vw;
    width: max-content;
    margin: 0;
  }

  .first-image-auth {
    width: 200px !important;
    position: unset !important;
    align-self: center;
  }
}

.second-image-auth {
  width: 180px;
  position: absolute;
  bottom: 10%;
  left: 9%;
  opacity: 0.8;
}

.first-image-auth {
  width: 170px;
  position: absolute;
  left: 30px;
  top: 30px;
  opacity: 0.8;
}

.disconnect{
  justify-content: center;
  align-items: center;
  display: flex;
  color: red;
}