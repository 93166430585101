.sigCanvas {
  border: 2px solid #e3ecf8;
  cursor: crosshair;
}

.initial-config-card {
  margin: 10% 8%;
}

.initial-config-steps-container {
  padding: 30px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.sig-pad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responsive-sigpad-msg {
  display: none;
}

.signature-radio-group {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .initial-config-card {
    margin: 10px 3px;
  }
  .initial-config-steps-container {
    padding: 0;
    margin-top: 0;
  }

  #initial-config-stripe .ant-btn-lg {
    height: auto;
  }
  #initial-config-stripe .ant-btn {
    white-space: inherit;
  }
  .sig-pad-container {
    display: none;
  }
  .responsive-sigpad-msg {
    display: block;
  }
  .signature-radio-group {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .signature-radio-group .ant-upload-list {
    max-width: 60vw;
  }
}
