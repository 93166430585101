
#dashboard-telec-element .ant-card-body { 
    padding: 24px;
}

#dashboard-telec-element .ant-card-head-title {
    font-size: 14px;
}

#water-wave-card .ant-card-body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-button-title {
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
    font-size: 16px;
}

.dashboard-incoming-telec-container {
    margin-right: 24px;
    flex: 63%;
    margin-bottom: 24px;
}

@media (max-width: 600px) {
    .dashboard-incoming-telec-container {
        margin-right: 0;
    }

    .dashboard-incoming-telec-container .ant-btn { 
        white-space: break-spaces;
    }

}