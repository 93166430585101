@import "../../app/styles.css";

.header {
  background-color: var(--main-bg-color);
}


.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  height: auto;
  background-color: #f8f9fb;
}


.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
  flex: 1;
justify-content: flex-end;
}

#nav {
  height: 100%;
  font-size: 12px;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
}

.icon-logo {
  display: none;
}

img.icon-logo {
  height: 32px;
  position: relative;
  bottom: 13px;
  left: 9px;
}

.show-sm {
  display: none;
}

.more-menu-icon {
  font-size: 1.6rem;
}

@media (max-width: 600px) {
  .full-logo {
    display: none;
  }
  .icon-logo {
    display: inherit;
  }
  .header-actions {
    margin-left: 12px;
    padding-right: 0px;
  }

  .header-top {
    justify-content: unset;
  }

  .show-lg {
    display: none;
  }

  .show-sm {
    display: inline-block;
    margin: 0 15px;
  }

  .header-acc-button {
    white-space: inherit !important;
    height: auto !important;
    margin-left: 5px;
  }
}
