#studies-form .item {
  margin: 5px;
}

#studies-form .add {
  margin-top: 10px;
  align-self: center;
}

#biography-form .item {
  max-width: 40%;
}

.studies-inputs-container {
  justify-content: space-around;
}

.diploma-input {
  flex: 1;
}

.ant-row .ant-form-item{
  display: flex;
}

.pricing-inputs-container > div {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
}

.ant-form-item-label{
  display: flex;
}
.ant-input-number{
  width:100%
}

#pricing-form .add {
  margin-top: 10px;
}

@media (max-width: 600px) {
  #profile-complete-card .user-card-identity {
    width: auto;
    margin-top: 12px;
  }
  .studies-inputs-container {
    justify-content: unset;
    flex-wrap: wrap;
    margin-left: -40px;
  }

  .diploma-input {
    flex: 0;
  }

  .studies-table {
    margin-left: -20%;
  }

  #biography-form .item {
    max-width: unset;
  }

  .studies-table .ant-table-cell:last-child {
    padding: 0;
  }

  .initial-config-last-result {
    padding: 0
  }

  .initial-config-last-result .ant-btn {
    height: auto;
    white-space: inherit;
  }

  #profile-complete-card .ant-transfer-list {
    width: 140px;
  }

  #profile-complete-card .studies-inputs-container {
    margin-left: 0;
  }

  #profile-complete-card .ant-form-item {
    max-width: 50vw;
  }
}
