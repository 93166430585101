.fc .fc-col-header-cell-cushion { /* needs to be same precedence */
    width: 50px; /* an override! */
    padding-bottom: 5px; /* an override! */
    font-size:16px;
    color:green;
}

.fc-daygrid-event-harness{
    background-color: rgb(201, 145, 129);
    margin: 5px;
}

.fc .fc-daygrid-day-events {
    margin-top: 15px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    color:white;
}