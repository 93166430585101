.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: min-content;
  max-height: calc(100vh - 180px);
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
  max-width: 35%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.documents-container {
  justify-content: space-between;
  align-content: center;
  align-items: flex-start
}

.doc-file-radio {
  height: 12em;
  width: 15em;
  margin: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.authorized-doctors-list .ant-list-item {
  padding: 16px 0;
}

.authorized-doctors-list .ant-list-item-action  button {
  color: #ff4d4f;
}

@media (max-width: 600px) {
  .images-container {
    flex-wrap: wrap;
  }
}
