#agenda .badge {
    margin: 5px;
  }
  
  #agenda .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
  
  @media (max-width: 600px) {
    #agenda .ant-btn-lg {
      height: auto;
    }
    #agenda .ant-btn {
      white-space: inherit;
    }
  
    #agenda-add-multiple .ant-radio-wrapper {
      white-space: inherit;
    }
  }
  
  .ant-descriptions-view table {
    width: 40%;
    table-layout: fixed;
  }
  