.telec-modal .ant-modal-body {
    padding: 0;
}

.telec-modal #dashboard-telec-element {
    margin: 33px 0 0 !important;
}

.device-icon {
    font-size: 35px;
    margin-top: 10px;
    color: #50abff;
}