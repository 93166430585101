.sidebar-menu-item {
  align-items: center;
  font-size: 12px;
}

.sidebar {
  background-color: white !important;
  border-right: 0px !important;
}

.sidebar.collapsible {
  position: absolute;
  z-index: 1;
  height: 100%;
}

.sidebar .ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 52px;
  line-height: 40px;
}

.sidebar .ant-divider-horizontal {
  margin: 12px 0;
}

.sidebar .ant-menu-item-selected {
  font-weight: 600;
  color: #f09f42 !important;
}

.sidebar .ant-menu-item {
  color: #000000 !important;
}

.sidebar .ant-menu-item {
  color: #000000 !important;
}

.sidebar .ant-menu-item:hover {
  color: #1890ff !important;
}

.sidebar .ant-menu-item-disabled {
  color: #000000 !important;
}

.sidebar .ant-layout-sider-trigger {
  background-color: #f04842;
}

.sidebar .ant-menu {
  color: #fff;
  margin-top: 34px;
}

.sidebar .ant-menu-sub.ant-menu-inline {
  background: inherit;
}
.sidebar .ant-menu-submenu-selected {
  color: #000000 !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-active {
  color: #000000 !important;
  font-weight: 600;
}

.sidebar .ant-menu-item-active {
  color: currentColor !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-arrow {
  color: #ffff;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-title {
  padding-left: 17px !important;
  color: #000000;
}

.sidebar .ant-menu-submenu-title-disabled {
  padding-left: 17px !important;
  color: #000000;
}

.ant-menu-item-disabled > .ant-menu-submenu-title {
  padding-left: 17px !important;
  color: #000000;
}

.sidebar.ant-layout-sider-collapsed {
  display: none;
}

.sidebar-collapse-trigger {
  position: fixed;
  z-index: 2;
  font-size: 1.75em;
  top: 15px;
  left: 15px;
}
