.user-card-container {
    justify-content: space-between;   
}

.user-card-statistics {
    align-items: center;
}

.user-card-identity {
    margin-left: 1rem;
    margin-top: 10px; 
}

.user-card-divider{
    height: 40px;
}

@media (max-width: 600px) {
    .user-card-container {
        margin-left: -16px;
    }

    .user-card-statistics {
        margin-top: 12px;
    }


    .user-card-divider {
        display: none;
    }
}